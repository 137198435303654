:root {
  --main-color: #add8e6;
  --main-color-medium: #7cbbcf;
  --main-color-dark: #478aa0;
  --main-color-very-dark: #386f81;
  --main-color-darkest: #20404b;
  --wild-pink: rgb(255, 83, 112);
  --wild-pink-brighter: rgb(255, 174, 187);
  --wild-pink-dark: rgb(109, 39, 51);
  --wrong-note-color: red;
  --grey-dark: #434343;
  --grey-very-dark: #303030;
  --grey-very-light: #f0f0f0;
  --guitar-brown: #df9c66;
  --guitar-inactive-fret: #c58a59;
  --guitar-string-grey: #8d8d8d;
  --guitar-fret-silver: rgb(78, 78, 78);
  --guitar-fret-shine: rgb(224, 224, 224);
  --guitar-fret-shine-2: rgb(224, 224, 224, 0.5);
  --guitar-shine: #d6d6d6;
  --guitar-nut: #f3f3e2;
  --guitar-dot-shine: #858585;
  --guitar-dot: #242424;
  --white: #f8f8f8;
  --max-width: 120rem;
}

html {
  font-size: 15px;
  box-sizing: border-box;
  color: var(--grey-dark);
}

@media (min-width: 1px) {
  html {
    font-size: 9px;
  }
}

@media (min-width: 30em) {
  html {
    font-size: 10px;
  }
}

@media (min-width: 48em) {
  html {
    font-size: 12px;
  }
}
@media (min-width: 62em) {
  html {
    font-size: 15px;
  }
}

/* @media (min-width: 80em) {
  html {
    font-size: 17px;
  }
}

@media (min-width: 96em) {
  html {
    font-size: 19px;
  }
} */

*,
*::before,
*::after {
  box-sizing: inherit;
}

.noHighlightOnClick {
  -webkit-tap-highlight-color: transparent;
}

.firebaseui-idp-text-long {
  min-width: 16rem;
}

.firebaseui-idp-icon {
  min-width: 1.5rem;
}

/* .firebaseui-idp-text-long {
  display: none !important;
} */

/* .firebaseui-idp-text-short {
  display: inline-block !important;
  display: none;
  min-width: 7rem;
}

.firebaseui-idp-button {
  display: flex !important;
} */
